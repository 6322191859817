import type { ReactNode } from 'react';

import type { EnrichedAnnotation } from '@hubcms/domain-story-elements';

import { getAnnotationElement } from './AnnotationElement';

/**
 * This function assumes that the annotations are already split and sorted correctly
 */
export function applyAnnotations(text: string, annotations: EnrichedAnnotation[], relativeStart = 0): ReactNode {
  const nodes = [];
  let endIndex = 0;

  annotations.forEach((current, idx, arr) => {
    const start = current.start - relativeStart;
    const end = current.end - relativeStart;

    // Plain text at start
    if (start > endIndex) {
      nodes.push(text.slice(endIndex, start));
    }

    // inner text
    if (start >= endIndex) {
      const innerText = text.slice(start, end);
      // Don't add tags for empty parts
      if (innerText.length) {
        const innerAnnotations = arr.filter(
          (inner, innerIdx) => inner.start >= current.start && inner.start < current.end && innerIdx !== idx,
        );
        const children = applyAnnotations(innerText, innerAnnotations, start + relativeStart);
        nodes.push(getAnnotationElement(current, children));
      }
      endIndex = end;
    }
  });
  // Plain text at end
  nodes.push(text.slice(endIndex));
  return nodes;
}
