import { Icon, Logo, Paragraph } from '@mediahuis/chameleon-react';
import { PodcastActive } from '@mediahuis/chameleon-theme-wl/icons';
import cx from 'classnames';
import type { ReactElement } from 'react';

import type { StoryblockBackgroundMode } from '@hubcms/domain-storyblock';

import styles from './labels.module.scss';

type LabelProps = {
  className?: string;
  label?: string;
  sublabel?: string;
  isPremium?: boolean;
  showPremiumIcon?: boolean;
  icon?: keyof typeof icons | null;
  mode?: StoryblockBackgroundMode;
};

const icons = {
  podcast: PodcastActive,
};

export default function Labels({
  className = '',
  sublabel,
  label,
  isPremium,
  icon,
  showPremiumIcon = true,
  mode = 'none',
  ...props
}: LabelProps): ReactElement | null {
  // Return null if there's nothing to render
  if (!sublabel && !label && !isPremium) return null;

  return (
    <div className={cx(styles.labels, styles[mode], className)} {...props}>
      {icon && <Icon as={icons[icon]} size="sm" className={cx(styles.icon)} />}
      {label && (
        <Paragraph as="span" className={styles.mainLabel}>
          {label}
        </Paragraph>
      )}
      {sublabel && (
        <Paragraph as="span" className={styles.sublabel}>
          {sublabel}
        </Paragraph>
      )}
      {showPremiumIcon && isPremium && (
        <Logo name="logo-premium-main" className={styles.premiumIcon} data-testid="premium-icon" />
      )}
    </div>
  );
}
