import { type ComponentType, createElement, Fragment, type PropsWithChildren } from 'react';

import type { EnrichedAnnotation } from '@hubcms/domain-story-elements';

import AnnotationLink from './AnnotationLink';

type AnnotationProps = PropsWithChildren<{
  annotation: EnrichedAnnotation;
}>;

const AnnotationMap: Record<string, string | ComponentType<AnnotationProps>> = {
  /* eslint-disable camelcase */
  bold: 'strong',
  italic: 'em',
  plaintext: Fragment,
  note: Fragment,
  underline: 'u',
  subscript: 'sub',
  superscript: 'sup',
  strike: 'strike',
  'mh-external_link': AnnotationLink,
  inline_link: AnnotationLink,
  internal_link: AnnotationLink,
  lineBreak: 'br',
  'mark-out': () => null,
  /* eslint-enable camelcase */
};

const SelfClosingElements: Array<keyof typeof AnnotationMap> = ['lineBreak'];

const AnnotationElement = ({ annotation, children }: AnnotationProps) => {
  const AnnotationNode = AnnotationMap[annotation.name] || 'span';
  if (typeof AnnotationNode === 'string') {
    return createElement(AnnotationNode, undefined, SelfClosingElements.includes(annotation.name) ? undefined : children);
  }
  return <AnnotationNode annotation={annotation}>{children}</AnnotationNode>;
};

export function getAnnotationElement(annotation: EnrichedAnnotation, children: PropsWithChildren['children']) {
  return <AnnotationElement annotation={annotation}>{children}</AnnotationElement>;
}

export default AnnotationElement;
