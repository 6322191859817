import { type ComponentProps, useEffect, useState } from 'react';

import { useAuth } from '@hubcms/data-access-auth';
import { HTML_EMBED_GUID_PATTERN, HtmlEmbed, type HtmlEmbedOptions } from '@hubcms/ui-embed';

import { loadHash } from '../../utils/loadHash';

export function HtmlEmbedWrapper(props: ComponentProps<typeof HtmlEmbed>) {
  if (props.htmlBlock.includes(HTML_EMBED_GUID_PATTERN)) {
    return <HTMLEmbedWithGuid {...props} />;
  }
  return <HtmlEmbed {...props} />;
}

function HTMLEmbedWithGuid(props: ComponentProps<typeof HtmlEmbed>) {
  const { user, isAuthenticated, isLoading } = useAuth();
  const [htmlEmbedOptions, setHtmlEmbedOptions] = useState<HtmlEmbedOptions | undefined>(undefined);

  useEffect(() => {
    const abortController = new AbortController();
    if (!isLoading && isAuthenticated && user && !htmlEmbedOptions) {
      loadHash(user.email, abortController.signal)
        .then(hash => {
          if (hash) {
            setHtmlEmbedOptions({ guid: user.sub, hash });
          }
          return hash;
        })
        .catch(error => {
          throw error;
        });
    }
    return () => {
      abortController.abort();
    };
  }, [user, isAuthenticated, isLoading, htmlEmbedOptions]);

  return <HtmlEmbed {...props} options={htmlEmbedOptions} />;
}
