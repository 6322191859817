import { Paragraph } from '@mediahuis/chameleon-react';
import cx from 'classnames';

import { heroBlockIntroProps } from '@hubcms/brand';
import { annotate } from '@hubcms/utils-story-elements';

import { Location } from '../Location';

import styles from './story-intro.module.scss';
import type { StoryIntroProps } from './types';

function StoryIntro({
  text,
  annotations,
  className,
  hasDropcap = false,
  fontWeight = 'default',
  locationData,
  ...paragraphProps
}: StoryIntroProps) {
  const locationEl = locationData ? <Location {...locationData} /> : null;
  const displayDropcap = !locationEl && hasDropcap;

  if (!text && !locationEl) {
    return null;
  }

  return (
    <Paragraph
      {...paragraphProps}
      {...heroBlockIntroProps}
      color="var(--story-intro-color, var(--semantic-foreground-base-adaptive-soft-fill))"
      weight={fontWeight}
      className={cx(className, styles.storyIntro, {
        [styles.dropcap]: displayDropcap,
      })}
      data-testid="article-intro"
    >
      {locationEl}
      {text && annotate(text, annotations)}
    </Paragraph>
  );
}

export default StoryIntro;
