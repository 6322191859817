import type { ElementType, ReactNode } from 'react';

import type { ElementRendererFn } from '@hubcms/data-access-story-elements';

import { ALIGNMENT_CLEAR_CLASSNAME, POST_ALIGNMENT_CLASSNAME } from '../domain/floating-constants';
import type { PreparedElement } from '../domain/prepared-element';

import { canAlignNextToFloatingElement } from './canAlignNextToFloatingElement';

type ConcatenatedElements = {
  elements: Array<{ key: string; jsx: ReactNode }>;
  isFloating: boolean;
};
export const INITIAL_CONCATENATION: ConcatenatedElements = {
  elements: [],
  isFloating: false,
};

type AdParams = {
  getAdOptions: (id: string) => string | null;
  AdElement: ElementType;
};

export function elementsReducer<T extends string>(renderElementFn: ElementRendererFn<T>, adParams?: AdParams) {
  return function (concatenated: ConcatenatedElements, current: PreparedElement, elementIdx: number) {
    const {
      Component,
      storyElement: { id, type, data },
      isFirstOfType,
      className,
      isAriaHidden,
    } = current;

    if (!Component) {
      return concatenated;
    }

    let concatenatedKeepsFloating = current.isFloating || concatenated.isFloating;

    const renderedElement = renderElementFn(<Component {...data} className={className} isAriaHidden={isAriaHidden} />, {
      isFirstOfType,
      elementType: type as T,
      index: elementIdx,
    });

    const elements = [{ key: id, jsx: renderedElement }];
    // If there is already a floating element above, detect if the current element should clear the float
    // We should exclude the POST_ALIGNMENT elements because a div clear between the pre and post alignment elements would make no sense, since those align around a floating element
    if (
      concatenated.isFloating &&
      !className.includes(POST_ALIGNMENT_CLASSNAME) &&
      !canAlignNextToFloatingElement(current.storyElement, true)
    ) {
      const key = `clear-after-${id}`;
      elements.unshift({ key, jsx: <div className={ALIGNMENT_CLEAR_CLASSNAME} data-testid={key}></div> });
      concatenatedKeepsFloating = false;
    }

    if (adParams) {
      const ad = getAd(adParams, id);
      if (ad && concatenatedKeepsFloating) {
        const key = `clear-for-ad-after-${id}`;
        elements.push({ key, jsx: <div className={ALIGNMENT_CLEAR_CLASSNAME} data-testid={key}></div> });
        concatenatedKeepsFloating = false;
      }

      if (ad) {
        /**
         * We can't use `unshift` here because the calculation of viable elements depends on displaying the ad AFTER the element.
         * If we define a sub_head as not viable, but we place an ad before a viable paragraph that follows the sub_head, the ad will be displayed after the sub_head (which is not viable)
         */
        elements.push({ key: `ad-after-${id}`, jsx: ad });
      }
    }

    return {
      elements: concatenated.elements.concat(elements),
      isFloating: concatenatedKeepsFloating,
    };
  };
}

function getAd({ getAdOptions, AdElement }: AdParams, id: string) {
  const adOptions = getAdOptions(id);

  if (!adOptions) {
    return null;
  }

  return <AdElement adFormat={`${adOptions}`} />;
}
