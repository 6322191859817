/* eslint-disable camelcase */
import type { NewsletterOptinEvent } from '@hubcms/domain-tracking';

export type NewsletterOptinData = Omit<NewsletterOptinEvent, 'event' | 'eventcategory'>;

export function createNewsletterOptinEvent(data: NewsletterOptinData): NewsletterOptinEvent {
  return {
    event: 'page_event',
    eventcategory: 'newsletter',
    eventaction: data.eventaction,
    eventlabel: data.eventlabel,
    ga_event_name: data.ga_event_name,
    itemdescription: data.itemdescription,
    itemoptions: data.itemoptions,
  };
}
