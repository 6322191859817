import cx from 'classnames';

import type { ImageClassName } from '@hubcms/data-access-story-elements';
import type { StoryElement, StoryElementType } from '@hubcms/domain-story-elements';
import { getElementAlignment } from '@hubcms/utils-story-elements';

import { PRE_ALIGNMENT_CLASSNAME } from '../domain/floating-constants';
import type { PreparedElement } from '../domain/prepared-element';

import { canAlignNextToFloatingElement } from './canAlignNextToFloatingElement';
import { getComponent } from './getComponent';
import { isFloatingElement } from './isFloatingElement';

const IMAGE_STORY_ELEMENT_TYPE: StoryElementType[] = ['image', 'gallery'];

export function prepareElement(imageClassName: ImageClassName) {
  return function (storyElement: StoryElement, elementIdx: number, elementArray: StoryElement[]): PreparedElement {
    const Component = getComponent(storyElement.type);

    const isFirstOfType = elementArray.findIndex(({ type }) => type === storyElement.type) === elementIdx;
    const isFloating = canAlignNextToFloatingElement(elementArray[elementIdx - 1]) && isFloatingElement(storyElement);
    const isNextElementFloating = canAlignNextToFloatingElement(storyElement) && isFloatingElement(elementArray[elementIdx + 1]);

    const classNames: string[] = [];

    if (isNextElementFloating) {
      classNames.push(PRE_ALIGNMENT_CLASSNAME);
    } else if (isFloating) {
      classNames.push(`mh-align-element-${getElementAlignment(storyElement)}`);
    }
    if (IMAGE_STORY_ELEMENT_TYPE.includes(storyElement.type) && imageClassName) {
      classNames.push(imageClassName);
    }

    return {
      storyElement,
      Component,
      isFirstOfType,
      isFloating,
      className: cx(...classNames),
    };
  };
}
