import type { ElementType } from 'react';

import type { StoryElementType } from '@hubcms/domain-story-elements';
import { Image, Gallery } from '@hubcms/ui-image';
import { LiveBlog } from '@hubcms/ui-live-blog';
import { Newsletter } from '@hubcms/ui-newsletter';
import {
  PageBreak,
  Paragraph,
  PullQuote,
  Heading,
  ReadMore,
  Map,
  Interview,
  Table,
  Embed,
  List,
  InfoBlock,
  SportsResult,
  Rating,
  SportsWidget,
} from '@hubcms/ui-story-elements';

import { AudioVisual } from '../components/AudioVisual';
import { HtmlEmbedWrapper } from '../components/HtmlEmbedWrapper';

type ElementComponentMap = Partial<Record<StoryElementType, ElementType>>;

export const ELEMENT_COMPONENT_MAP: ElementComponentMap = {
  /* eslint-disable camelcase */
  audiovisual: AudioVisual,
  code_block: HtmlEmbedWrapper,
  embed: Embed,
  gallery: Gallery,
  image: Image,
  infoblock_group: InfoBlock,
  infoblock_paragraph: Paragraph,
  infoblock_image: Image,
  infoblock_list_numbered: List,
  infoblock_list_bulleted: List,
  infoblock_headline: Heading,
  infoblock_sub_head: Heading,
  interview: Interview,
  list_numbered: List,
  list_bulleted: List,
  live_blog: LiveBlog,
  map: Map,
  mhbe_video: Embed,
  newsletter_widget: Newsletter,
  page_break: PageBreak,
  paragraph: Paragraph,
  pull_quote: PullQuote,
  rating: Rating,
  relation: ReadMore,
  sports_group: InfoBlock,
  sports_result: SportsResult,
  sports_widget: SportsWidget,
  sub_head: Heading,
  summary_group: InfoBlock,
  table: Table,
  /* eslint-enable camelcase */
};
