import type { TrackingObject } from '@news-components/newsletter-subscription-widget';

import { sendTrackingEvent } from '@hubcms/data-access-tracking';
import { createNewsletterOptinEvent, mapClickNewsletterOptinData, mapShowNewsletterOptinData } from '@hubcms/utils-tracking';

export const handleNewsletterTrackingEvent = (eventData: TrackingObject) => {
  switch (eventData.eventaction) {
    case 'show': {
      const showNewsletterOptinData = mapShowNewsletterOptinData(eventData);
      const newsletterOptinShowEvent = createNewsletterOptinEvent(showNewsletterOptinData);
      return sendTrackingEvent({
        ...newsletterOptinShowEvent,
        conditions: {
          afterEvent: 'page_view',
        },
      });
    }
    case 'click': {
      const clickNewsletterOptinData = mapClickNewsletterOptinData(eventData);
      const newsletterOptinClickEvent = createNewsletterOptinEvent(clickNewsletterOptinData);
      return sendTrackingEvent(newsletterOptinClickEvent);
    }
    default:
      return null;
  }
};
