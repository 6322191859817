/* eslint-disable camelcase */
import type { NewsletterOptinData } from './create-newsletter-optin-event';

export function mapClickNewsletterOptinData(data: NewsletterOptinData): NewsletterOptinData {
  return {
    eventaction: data.eventaction ?? 'click',
    eventlabel: data.eventlabel ?? '',
    ga_event_name: data.ga_event_name ?? null,
    itemdescription: data.itemdescription ?? null,
    itemoptions: data.itemoptions ?? [],
  };
}
